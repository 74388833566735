import { SchemaOrgType } from "./schemaOrgUtils.server";

type SchemaOrgProps = {
  content: SchemaOrgType;
};

export default function SchemaOrg({ content }: SchemaOrgProps) {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(content) }}
    />
  );
}
